<template>
  <div class="sellgold" @click="isDo()">
     <van-nav-bar
            title="买料"
            safe-area-inset-top
            fixed
            left-arrow
            placeholder
            :border="false"
             @click-left="onClickLeft"
        />
    <!-- 实时金价 -->
    <div class="rel">
      <center class="boxgoldprice">
        <h2 :style="{color:redColor?'#FF2D2E':'#02790D'}">{{businessTypeID === '4' ? (pricebox - orderBuyPrice).toFixed(2) : pricebox}}</h2>
        <div class="pricebox">{{initCity}}</div>
        <div class="premium" v-if="pricePremium.enable === 'ENABLE' && businessTypeID === '0'">
          今日水点：<span style="color: #FF2D2E">{{pricePremium.sellPremium}}</span>
        </div>
      </center>
      <div class="bottombox">
      <!-- 参数选择 -->
        <!-- <div v-if="!loadingtrue"><center class="centerboxloading"><van-loading   size="50"  /> </center></div> -->
        <div class="userselect">
         <van-cell title="订单类型" is-link :value="initCity" @click="showPopup"  size="large"/>
<!--        <van-cell title="订单类型"  :value="initCity"   size="large"/>-->
        <!-- <div class="title_box">订单类型 </div>
        <div @click="showPopup" :class="issslect? 'citybox drepcolor' : ' citybox'">{{initCity}}</div> -->
        <van-cell title="业务类型" is-link  :value="businessName"  @click="sheetShow = true"  size="large"/>
        <!-- <van-cell title="业务类型"       size="large">
              <template #default>
                  <div style="color:#999999">{{businessName}}</div>
              </template>
        </van-cell> -->

        <div class="title_box ">
            <div class="flex-between weightbox">
                <span>重量</span>
                <el-input v-model="weight" placeholder="0" type="number" @input="handleInput2" maxlength="6" :class="isinput?'yellowfont':''" :disabled="disabled"></el-input>
                <div class="unit">克</div>
            </div>
        </div>
        <div class="title_box " v-if="businessTypeID === '1' ">
          <van-radio-group v-model="radio" direction="horizontal" @change="radioChange">
            <van-radio name="one" icon-size="16" checked-color="red" >1000g</van-radio>
            <van-radio name="two" icon-size="16" checked-color="red">2000g</van-radio>
            <van-radio name="three" icon-size="16" checked-color="red">3000g</van-radio>
            <van-radio name="five" icon-size="16" checked-color="red">5000g</van-radio>
          </van-radio-group>
        </div>
<!--          <div class="title_box " v-if="businessTypeID === '3' ">
            <van-radio-group v-model="radioMini" direction="horizontal" @change="radioChangeMini">
              <van-radio name="one" icon-size="16" checked-color="red" >100g</van-radio>
              <van-radio name="two" icon-size="16" checked-color="red">200g</van-radio>
              <van-radio name="three" icon-size="16" checked-color="red">300g</van-radio>
              <van-radio name="five" icon-size="16" checked-color="red">500g</van-radio>
            </van-radio-group>
          </div>-->
        <div class="title_box " v-if="businessTypeID != '4' ">
            <div class="flex-between weightbox">
                <span>定金 <i class="dingJIn">(可用定金 <span class="dingJinData">{{freeEarnestMoney}}</span> )</i> </span>
                <div class="countMumber"  :style="{color:isinput?'#242424':'#D1D1D1'}"> <span class="zuiDi">最低定金</span> {{countMumber}}</div>
                <div class="unit">元</div>
            </div>
        </div>
<!--          <div class="title_box " v-if="businessTypeID != '0'">
            <div class="flex-between weightbox">
              <div>
                <span>可下单金额</span>
                <el-tooltip effect="dark" content="大于可用定金可下单"  placement="top-start">
                  <van-icon name="question-o" color="#FF2D2E"  />
                </el-tooltip>
              </div>
              <div class="countAvailableMoney"  :style="{color:'#D1D1D1'}">{{countAvailableMoney}}</div>
              <div class="unit">元</div>
            </div>
          </div>-->
          <!-- <div class="title_box">
      <div class="textTip">温馨提示: {{buyTips}}</div>
          </div> -->
        </div>
      </div>
      <div class="btnbox" :style="{background: Number(weight) ?'#ff2d2e':'#f7cdcd'}" @click="postData('showpop')">立即买料</div>

      <div class="tipBox">
        <div>
          温馨提示
          <div class="leftLine"></div>
          <div class="rightLine"></div>
          </div>
          <div v-html="buyTips"></div>
      </div>
<!--      <div>

        <van-popup v-model="show" position="bottom">
          <van-area :area-list="areaList"   @confirm="confirmF" cancel-button-text=" "/>
        </van-popup>
      </div>-->

      <van-action-sheet
        v-model="show"
        :actions="category"
        cancel-text="取消"
        close-on-click-action
        @select="confirmCategory"
      />

      <van-action-sheet
          v-model="sheetShow"
          :actions="businessType"
          cancel-text="取消"
          close-on-click-action
           @select="onSelect"
        />
      <!-- 买金须知 -->
      <!-- <van-dialog v-model="tipsell">
            <div class="title">买金须知</div>
            <div class="centerbox_dialog _leftbox">
              <p>1、我司仅支持实物贵金属回收，黄金各品类定价选择黄金9999即可,收到货物后将根据黄金9999的折足克重为您结算。</p>
              <p>2、您的定价审核通过后请在72小时内将货物寄出，只定价不发货属于违约行为，我方有权追究法律责任，请谨慎定价。</p>
            </div>
            <div class="gocer" @click="tipsell=false">确定</div>
        </van-dialog> -->
        <!-- 定价确认 -->
        <van-dialog v-model="showorder">
          <div class="title">订单确认</div>
          <div class="confirmCenterbox_dialog">

            <div class="flex orderInfo">  <div class="orderCol">订单类型</div>  <div>{{initCity}}实物</div> </div>
            <div class="flex orderInfo">   <div class="orderCol">业务类型</div>  <div>{{businessName}}</div></div>
            <div class="flex orderInfo">  <div class="orderCol">重量</div>  <div>{{weight}}g</div></div>
            <div class="flex orderInfo">   <div class="orderCol">单价</div>  <div> <span style="color:#FF4B71">{{businessTypeID === '4' ? (pricebox - orderBuyPrice).toFixed(2) : pricePremium.enable === 'ENABLE' && businessTypeID === '0' ? (pricebox + pricePremium.sellPremium).toFixed(2) : pricebox}}</span> 元/克</div></div>
            <div class="flex orderInfo">   <div class="orderCol">押金</div>  <div>{{countMumber}}元</div></div>
          </div>
          <!-- <div class="text">温馨提示:{{buyTips}}.</div> -->
          <div class="autograph">
                  <div class="autographText">手写签名</div>
                  <div class="autographBox">
                    <img  v-if="img" :src="img" alt="">
                  </div>
          </div>
          <div class="flex popbtn">
            <div class="gocer closebtn" @click="closepop">取消</div>
            <div class="gocer confirmbtn" @click.stop="postData('postDataf')">确定</div>
          </div>
        <div class="Loading"><van-loading  v-if="Loading"  size="30"  /></div>

        </van-dialog>

        <van-dialog v-model="ceringModel" class="ceringModel">
            <div class="title">提示</div>
            <div class="centerbox_dialog">{{businessTypeID === '1' ? stopTrading : stopMiniTrading}}</div>
            <div class="confirm" @click="ceringModel=false">确定</div>
        </van-dialog>
      <van-dialog v-model="depositModel" class="ceringModel">
        <div class="title">提示</div>
        <div class="centerbox_dialog" style="border-bottom: none">当前押金不足，请先补交足够押金再进行定价</div>
        <div class="flex popbtn">
          <div class="gocer closebtn" @click="depositModel = false">取消</div>
          <div class="gocer confirmbtn" @click.stop="goDeposit">确定</div>
        </div>
      </van-dialog>
    </div>
  </div>

</template>
<script>
import Stomp from 'stompjs'
import 'vant/lib/popup/style';
import 'vant/lib/area/style';
import AreaList from './select_type';
import * as PriceLineChart from '@/utils/api'
// import ReconnectingWebSocket from 'reconnectingwebsocket'
import headbox from'@/components/head.vue'
import Decimal from 'decimal.js'
import { watch } from 'vue';

export default {
  data(){
    return{
      tipcenter: false,
      showorder: false,
      tipsell: false,
      redColor:true, //判断跌涨
      secondlastData: [],
      // 首次加载的数据
      initDatalist: [],
      firstloadData: true,
      ceringModel:false,
      depositModel: false, // 押金modal
      // 防止重复提交
      isclick: false,
      // 第二次重复提交
      secondClick: false,
      // 提交的时间
      postTime: '',
      // 选择的产品key
      productkey: 'huangjin9999',
      // 是否输入状态
      isinput: false,
      // 预估金额
      countMumber:'0',
      countAvailableMoney: "0",
      countAvailableMoneyObject: {},
      loadingtrue: false,
      // 可用定金
      freeEarnestMoney:'',
      // 所有商品数组
      allpro:[],
      pricebox: '--.--',
      anotherPricebox: '--.--',
      issslect: true,
      areaList: AreaList,
      sheetShow:false,
      show: false,
      initCity: '黄金',
      // 业务类型
      businessType:[{name:'近期提料',id:'0'},{name:'90天内提料(大)',id:'1'},{name:'90天内提料',id:'3'},{name:'提料福利',id:'4'}],
      // category: [{name:'黄金',id:'0'},{name:'铂金',id:'1'},{name:'钯金',id:'2'},{name:'白银',id:'3'}],
      category: [{name:'黄金',id:'0'}, {name:'铂金',id:'1'}, {name:'白银',id:'3'}],
      businessName:'近期提料',
      businessTypeID:'0',
      keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
      // 卖出克重
      weight: '',
      wsUrl : localStorage.getItem('ws'),
      trade_: false,
      client:{},
      img:'',
      key:'',
      depositProportion:{},
      InsufficientDeposit:false,
      Loading:false,
      tips:{
        buyRecentTips:'', //近期提料
        buyDelayTips:''// 延期提料
      },
      buyTips:'',  //温馨提示
      stopTrading:'',//停盘说明
      disabled: false, //克重是否可输入
      miniOrderPolicy: 30, // 小克重下单押金策略
      miniOrderTime: [],  // 小克重下单时间
      stopMiniTrading: "", // 小克重停盘说明
      radio: '',
      radioMini: '',
      orderBuyWeight: '',
      orderBuyPrice: '',
      orderOpen: false,
      pricePremium: {
        enable: "DISABLE",
        sellPremium: 0
      }, // 销售溢价水点设置
      lastTime: null, //最后一次点击的时间
      currentTime: null, //当前点击的时间
      timeOut: 30 * 1000, //设置超时时间： 30s
    }
  },
  // activated(){
  //     this.img = this.$route.query.img

  // },
  // deactivated(){
  //   this.img = ''
  // },
  mounted() {

    // PriceLineChart.mortgageApi('UNDELIVERED',0).then(res=>{
      // if(res.content.length>=1){
      //   this.tipsell = false
      // }else{
      //     const isshowpop = localStorage.getItem('first_page_box')
      //     if(isshowpop){
      //         this.tipsell = false
      //     }else{
      //       localStorage.setItem('first_page_box',true)
      //       this.tipsell = true
      //     }
      // }
    // })
    this.pricebox = this.$route.query.price
    this.connect()
  },
  watch:{
    allpro:function(){
      if(this.initCity != '请选择类别'){
        this.updatearr(this.initCity)
      }
    },
    weight(newval,oldVal){
        if (!newval) {
            this.countMumber = 0
            this.isinput = false
        }
    },
    countMumber(newval,oldVal){
      if (newval > this.freeEarnestMoney) {
        this.InsufficientDeposit = true
        this.weight =0
        this.radio = ''
        this.radioMini = ''
           // this.$toast('定金不足!请充值')
        this.depositModel = true
      }else{
        this.InsufficientDeposit = false

      }
    },
    pricebox(newval,oldVal){
      newval>oldVal?this.redColor = true:this.redColor = false
    },
/*    anotherPricebox(newval, oldVal){
      if(JSON.stringify(this.countAvailableMoneyObject) !== "{}"){
        this.countAvailableMoney = this.countAvailableMoneyObject.deposit - ((newval * this.countAvailableMoneyObject.waitFetchWeight).toFixed(2)- this.countAvailableMoneyObject.waitFetchMoney) - this.countAvailableMoneyObject.buyDepositLimit * this.countAvailableMoneyObject.waitFetchWeight
      }
      else{
        this.countAvailableMoney = 0
      }
    }*/

  },
  components:{
    headbox,
  },
  destroyed() {
    clearTimeout(this.setInterval)
    this.client.disconnect(function() {
      // 断开连接
      // console.log("See you next time!");
    })
  },
async  created(){
    const res = await PriceLineChart.getDefaultExplain({ title: "" });
    res.forEach(item => {
      if (item.title === "buyRecentTips") {
        this.tips.buyRecentTips = item.content;
        this.buyTips = item.content;
      } else if (item.title === "buyDelayTips") {
        this.tips.buyDelayTips = item.content;
      } else if (item.title === "stopTradingDescription") {
        this.stopTrading = item.content;
      } else if (item.title === "stopTradingSamllDescription") {
        this.stopMiniTrading = item.content;
      }
    });

             // 查询可用定金接口
  await PriceLineChart.getAvailableMoney().then(res=>{
      this.freeEarnestMoney = res.freeEarnestMoney
    });
  // 计算可下单金额
/*  await PriceLineChart.getCountAvailableMoney().then(res=>{
    this.countAvailableMoneyObject = res
    this.countAvailableMoney = this.countAvailableMoneyObject.deposit - ((this.anotherPricebox * this.countAvailableMoneyObject.waitFetchWeight).toFixed(2)- this.countAvailableMoneyObject.waitFetchMoney) - this.countAvailableMoneyObject.buyDepositLimit * this.countAvailableMoneyObject.waitFetchWeight
  });*/
  // 获取小克重订单押金策略
  await PriceLineChart.getMiniOrderPolicy().then(res=>{
    this.miniOrderPolicy = res.buy
  })
  await PriceLineChart.getMiniOrderTime().then(res=>{
    this.miniOrderTime = res.orderTime
  })
  // 提料福利配置设置
  await PriceLineChart.getOrderBuy().then(res=>{
    this.orderBuyWeight = res.weight
    this.orderBuyPrice = res.price
  })
  // 提料福利开关配置
  await PriceLineChart.getOrderBuyOpen().then(res=>{
    this.orderOpen = res.open
  })
  // 销售溢价水点设置
  await PriceLineChart.getPricePremium().then(res=>{
    this.pricePremium = res
  })
      let user = JSON.parse(localStorage.getItem('userinfo'))
  const businessTypeTemp = [{name:'近期提料',id:'0'}]
  if (user.macroOrder) {
    businessTypeTemp.push({name:'90天内提料(大)',id:'1'})
  }
  if (user.miniOrder) {
    businessTypeTemp.push({name:'90天内提料',id:'3'})
  }
  if (this.orderOpen) {
    businessTypeTemp.push({name:'提料福利',id:'4'})
  }
  this.businessType = businessTypeTemp
/*  if(user.macroOrder){
    this.businessName = '90天内提料(大)'
    this.businessTypeID = '1'
  } else if (user.miniOrder) {
    this.businessName = '90天内提料'
    this.businessTypeID = '3'
  }*/
      this.depositProportion = user.depositDetail.curBuy
      this.img = user.sign
        this.showorder = false
      this.lastTime = new Date().getTime();   // 网页第一次打开时，记录当前时间

  },
  methods: {
    onClickLeft(){
      this.$router.push({
        name:'pricing'
      })
      this.weight = ''

    },
    // 业务类型选择
    onSelect(item){
      this.businessName = item.name
      this.businessTypeID = item.id
      this.isinput = false;
      this.disabled = false;
      this.weight = '';
/*      if(item.id === '3'){
        this.isinput = true;
        this.disabled = true;
        this.weight = 100;
        this.countMumber = this.miniOrderPolicy * this.weight;
      } else {
        this.isinput = false;
        this.disabled = false;
        this.weight = 0;
      }*/
      if (item.id == '0' || item.id == '4') {
            // 近期提料
            this.buyTips = this.tips.buyRecentTips
      }else{

            this.buyTips = this.tips.buyDelayTips
      }
      if(item.id == '4') {
        this.isinput = true;
        this.disabled = true;
        this.weight = this.orderBuyWeight;
      }
    },
    // 手写签名按钮事件
    // linkAutograph(){
    //   this.$router.push({
    //     name:'autograph',
    //     query:{path:'Buygold'}
    //   })
    // },
    showTip(){
      if(this.tipcenter){
        return false
      }
      this.tipcenter = true
      setTimeout(()=>{
        this.tipcenter = false
      },3000)
    },
    closepop(){
      this.showorder=false
      this.isclick=false
    },
    postData(type) {
      this.currentTime = new Date().getTime(); // 记录这次点击的时间
      //判断上次最后一次点击的时间和这次点击的时间间隔是否大于10分钟
      if (this.currentTime - this.lastTime > this.timeOut) {
        // 这里写状态已过期后执行的操作
        this.$toast({
          type: "fail",
          message: "当前页面停留时间过长",
          forbidClick: true,
          onClose: () => {
            this.showorder = false;
            this.lastTime = new Date().getTime();
          }
        });
      } else {
        this.lastTime = new Date().getTime(); //如果在30钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
        if (type == 'postDataf') {
          if (this.secondClick) {
            setTimeout(() => {
              this.secondClick = false;
            }, 1000)
            return false
          }
          this.secondClick = true
          const postData = {
            "addr": '',
            "lat": 0,
            "lng": 0,
            "order": [
              {
                "key": this.productkey,
                "weight": this.weight
              }
            ],
            "time": this.postTime,
            "businessType": this.businessTypeID === '4' ? '0' : this.businessTypeID,
            "fuli": this.businessTypeID === '4' ? 1 : 0,
          }
          PriceLineChart.buygold(postData).then(res => {
            this.weight = ''
            this.countMumber = 0
            this.radio = ''
            this.radioMini = ''
            this.isinput = false
            this.secondClick = false
            this.showorder = false
            this.countAvailableMoney = 0  // 不确定是否需要重新计算
            this.$router.push({ name: 'successpage', query: { type: 'buy' } })
          }).catch(err => {

          })
        } else {
          if (this.isclick) {
            setTimeout(() => {
              this.isclick = false;
            }, 1000)
            return false
          }
          if (!this.trade_) {
            this.ceringModel = true
            return false
          }
          this.isclick = true
          if (!Number(this.weight)) {
            return this.$toast('克重不能为空'), !1
          }
          if (this.InsufficientDeposit) {

            return this.$toast('定金不足!请充值'), !1
          }
          if (this.businessTypeID !== "1") {
            if (this.businessTypeID === "3") {
/*              if (this.weight < 10 || this.weight > 500) {
                return this.$toast('仅支持100-500之间的整数倍订单'), !1
              } else if (this.weight % 10 != 0 || (this.weight > 100 && this.weight % 100 != 0)) {
                return this.$toast('仅支持100的整数倍订单'), !1
              }*/
              if (this.weight % 10 != 0) {
                return this.$toast('仅支持10的整数倍订单'), !1
              }
            }
            if (this.initCity === "白银" && this.weight < 1000) {
              return this.$toast('最低1000起步'), !1
            }
            if (this.initCity === "白银" && this.weight % 1000 != 0 && this.weight != 0) {
              return this.$toast('仅支持1000的整数倍订单'), !1
            }
            if (this.businessTypeID == '0' && this.weight < 10) {
              return this.$toast('最低10g起步'), !1
            }
            if (this.initCity === "铂金" && this.weight % 100 != 0) {
              return this.$toast('仅支持100的整数倍订单'), !1
            }
            const nowTime = new Date();
            const date1 = new Date(this.miniOrderTime[0].start * 1000);
            const date2 = new Date(this.miniOrderTime[0].end * 1000);
            const date3 = new Date(this.miniOrderTime[1].start * 1000);
            const date4 = new Date(this.miniOrderTime[1].end * 1000);
            const nowTime1 = this.timeSwitch(date1);
            const nowTime2 = this.timeSwitch(date2);
            const nowTime3 = this.timeSwitch(date3);
            const nowTime4 = this.timeSwitch(date4);
            if ((nowTime.getTime() - nowTime1.getTime() > 0 && nowTime.getTime() - nowTime2.getTime() < 0) ||
              (nowTime.getTime() - nowTime3.getTime() > 0 && nowTime.getTime() - nowTime4.getTime() < 0)) {
              this.showorder = true;
              return false;
            } else {
              this.ceringModel = true;
              return false;
            }
          }
          if (this.businessTypeID == '1' && this.weight < 1000) {
            return this.$toast('最低1000起步'), !1
          } else if (this.businessTypeID == '1' && this.weight % 1000 != 0 && this.weight != 0) {
            return this.$toast('仅支持1000的整数倍订单'), !1
          }
          this.showorder = true
          this.isclick = false
        }
      }
    },
    timeSwitch(date1){
      const nowTime1 = new Date();
      nowTime1.setHours(date1.getHours())
      nowTime1.setMinutes(date1.getMinutes())
      nowTime1.setSeconds(date1.getSeconds())
      return nowTime1
    },
    handleInput2(e){
      this.radio = ''
      this.radioMini = ''
      if(this.weight == 0){
        this.weight = ''
        return !1
      }else{
        this.isclick = false
        this.isinput = true
        this.weight = (this.weight.match(/^\d*(\.?\d{0,2})/g)[0]) || null
        const categoryPolicy = this.initCity === '白银' ? this.depositProportion.agPolicy :
          this.initCity === '铂金' ? this.depositProportion.ptPolicy :
            this.initCity === '钯金' ? this.depositProportion.pdPolicy : this.depositProportion.policy
        this.countMumber = +categoryPolicy ? this.businessTypeID == '3' ? new Decimal(+this.miniOrderPolicy).mul(new Decimal(+this.weight)) : new Decimal(+categoryPolicy).mul(new Decimal(+this.weight)) : new Decimal(+categoryPolicy).mul(new Decimal(+this.weight))
        // debugger
      }
    },
    unzip(b64Data){
        let strData   = atob(b64Data);
        const charData  = strData.split('').map(function(x){return x.charCodeAt(0);});
        const binData   = new Uint8Array(charData);
        const data    = pako.inflate(binData);
        strData   = String.fromCharCode.apply(null, new Uint16Array(data));
        return decodeURIComponent(escape(strData));
    },
    onConnected() {
      const topic = '/price/all'
      this.client.subscribe(topic, this.responseCallback, this.onFailed)
    },
    connect () {
       this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
        const headers = {
        'login': 'username',
        'passcode': 'password',
      }
      this.client.connect( headers, this.onConnected, this.onFailed)
      this.client.debug = null

    },
    onFailed (frame) {
      console.log('错误: ' + frame)
      if (!this.client.connected) {
        setTimeout(() => {
          this.connect();
        }, 1000);
      }
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString
      return toString.call(val) === '[object Object]'
    },
    deepMerge(...objs) {
      const than = this
      const result = Object.create(null)
      objs.forEach(obj => {
        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key]
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val)
              } else {
                result[key] = than.deepMerge(val)
              }
            } else {
                //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val]
              } else {
                result[key] = val
              }
            }
          })
        }
      })
      return result
    },
    responseCallback (frame) {
      this.allpro = []
      const resbody = eval('['+this.unzip(frame.body)+']')[0]
      const parse_res = JSON.parse(JSON.stringify(resbody));
      this.trade_ = resbody.trade;
      this.postTime = resbody.time
      // console.log('postTime1',this.postTime);
      if(this.firstloadData){
        this.initDatalist = resbody
        this.firstloadData = false
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }else{
        this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }
      // this.loadingtrue = true
    },
    // 价格变动更新视图
    pricenew(name){
      this.allpro.find((x)=>{
        if(x[name]){
          this.pricebox = x[name]['xiaoshou']
          this.anotherPricebox = x[name]['huigou']
        }
      })
    },
    // 更新数组
    updatearr(name){
      if(name == '黄金'){
        this.pricenew('huangjin9999')
        this.productkey = 'huangjin9999'
      }else if(name == '黄金金条999'){
        this.pricenew('huangjin999jt')
        this.productkey = 'huangjin999jt'
      }else if(name == '黄金首饰999'){
         this.pricenew('huangjin999ss')
         this.productkey = 'huangjin999ss'
      }else if(name == '黄金18K 750'){
         this.pricenew('huangjin750')
         this.productkey = 'huangjin750'
      }else if(name == '黄金(无法确定)'){
         this.pricenew('huangjin9999')
         this.productkey = 'huangjin9999'
      }else if(name == '铂金'){
         this.pricenew('bojin9996')
         this.productkey = 'bojin9996'
      }else if(name == '铂金首饰999'){
         this.pricenew('bojin999')
         this.productkey = 'bojin999'
      }else if(name == '铂金首饰990'){
         this.pricenew('bojin900')
         this.productkey = 'bojin900'
      }else if(name == '铂金首饰950'){
         this.pricenew('bojin950')
         this.productkey = 'bojin950'
      }else if(name == '铂金(无法确定)'){
         this.pricenew('bojin9996')
         this.productkey = 'bojin9996'
      }else if(name == '钯金'){
         this.pricenew('bajin9996')
         this.productkey = 'bajin9996'
      }else if(name == '钯金首饰999'){
         this.pricenew('bajin999')
         this.productkey = 'bajin999'
         this.pricenew('bajin990')
         this.productkey = 'bajin990'
      }else if(name == '钯金首饰950'){
         this.pricenew('bajin950')
         this.productkey = 'bajin950'
         this.pricenew('bajin9996')
         this.productkey = 'bajin9996'
      }else if(name == '白银'){
         this.pricenew('baiyin9999')
         this.productkey = 'baiyin9999'
      }else if(name == '白银首饰999'){
         this.pricenew('baiyin999')
         this.productkey = 'baiyin999'
      }else if(name == '白银首饰990'){
         this.pricenew('baiyin990')
         this.productkey = 'baiyin990'
      }else if(name == '白银首饰925'){
         this.pricenew('baiyin925')
         this.productkey = 'baiyin925'
      }else if(name == '白银(无法确定)'){
         this.pricenew('baiyin9999')
         this.productkey = 'baiyin9999'
      }
    },
    confirmF(picker, value, index){
      this.show = false;
      this.issslect = true
      this.initCity =   picker[0].name
      console.log(this.initCity);

      this.weight = ''
      this.countMumber = '0'
      this.radio = ''
      this.radioMini = ''
      this.countAvailableMoney = "0"
      this.updatearr(picker[0].name)
    },
    confirmCategory(item){
      this.show = false;
      this.initCity = item.name;
      console.log('initCity',this.initCity);
      let user = JSON.parse(localStorage.getItem('userinfo'))
      const businessTypeTemp = [{name:'近期提料',id:'0'}]
      if (user.macroOrder) {
        businessTypeTemp.push({name:'90天内提料(大)',id:'1'})
      }
      if (user.miniOrder) {
        businessTypeTemp.push({name:'90天内提料',id:'3'})
      }
      if (this.orderOpen) {
        businessTypeTemp.push({name:'提料福利',id:'4'})
      }
      this.businessType = businessTypeTemp
/*      if(user.macroOrder){
        this.businessName = '90天内提料(大)'
        this.businessTypeID = '1'
      } else if (user.miniOrder) {
        this.businessName = '90天内提料'
        this.businessTypeID = '3'
      }*/
      if(this.initCity === "白银" || this.initCity === "铂金") {
        this.businessType = [{name:'近期提料',id:'0'}]
        this.businessName = '近期提料'
        this.businessTypeID = '0'
      }
      this.weight = ''
      this.countMumber = '0'
      this.radio = ''
      this.radioMini = ''
      this.countAvailableMoney = "0"
      this.updatearr(item.name)
    },
    showPopup() {
      this.show = true;
    },
    radioChange(name){
      this.weight = this.radio === "one" ? 1000 : this.radio === "two" ? 2000 : this.radio === "three" ? 3000 : this.radio === "five" ? 5000 : this.weight || ''
      if (this.radio) {
        this.handleInput1('')
      }
    },
    radioChangeMini(name){
      this.weight = this.radioMini === "one" ? 100 : this.radioMini === "two" ? 200 : this.radioMini === "three" ? 300 : this.radioMini === "five" ? 500 : this.weight || ''
      if (this.radioMini) {
        this.handleInputMini('')
      }
    },
    handleInput1(e){
      this.isclick = false
      this.isinput = true
      const categoryPolicy = this.initCity === '白银' ? this.depositProportion.agPolicy :
        this.initCity === '铂金' ? this.depositProportion.ptPolicy :
          this.initCity === '钯金' ? this.depositProportion.pdPolicy : this.depositProportion.policy
      this.countMumber = new Decimal(+categoryPolicy).mul(
        new Decimal(this.weight)
      );
    },
    handleInputMini(e) {
      this.isclick = false;
      this.isinput = true;
      this.countMumber = new Decimal(+this.miniOrderPolicy).mul(
        new Decimal(this.weight)
      );
    },
    isDo() {
      this.lastTime = new Date().getTime(); //如果在30钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
    },
    goDeposit() {
      this.$router.push({ name: "deposit" });
    }
  },
}
</script>
<style lang="scss" scoped="true">
::v-deep{
     .van-nav-bar{
        height: 100px;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
        background-color: #fff;
        .van-nav-bar__title{
            font-size: 36px;
            color: #242424;
        }
        .van-icon{
            font-size: 40px;
            color: #242424;

        }
        }
     }
    .van-radio-group--horizontal{
      justify-content: space-around;
    }
}
::v-deep{
    .van-cell:after{
       border-bottom: 1px solid #E0E0E0 !important;
    }
  .van-cell{
    padding: 30px  20px;

    .van-cell__title{
      font-size: 36px;
      color: #666666;
      line-height: 50px;
    }
    .van-cell__value{
      font-size: 36px;
      color: #242424;
      line-height: 50px;
    }
    .van-icon{
      font-size: 36px;
      color: #9d9d9d;
      line-height: 50px;
    }
  }
  .van-picker__columns{
    display: block;
  }
  //.van-popup.van-popup--round.van-popup--bottom.van-popup--safe-area-inset-bottom.van-action-sheet{
  //  height: 300px;
  //}
  //.van-action-sheet{
  //  height: 400px !important;
  //}
  .van-action-sheet__item{
    line-height: 80px;
    font-size: 32px;
  }
  .van-action-sheet__cancel, .van-action-sheet__item{
    font-size: 32px;
  }
}
.dingJIn{
   font-style: normal;
   font-size: 24px;
   .dingJinData{
   font-size: 24px;
   color: red;
   }
}
.tipboxback{
  color:#f2aa2e;
  background:rgba(0,0,0,.8);
  border-radius: 8px;
  padding: 5px 15px;
  display: inline-block;
  position: absolute;
  left: 0;
  top:-25px;
  &:after{
    content: '';
    width: 0;
    height: 0;
    left: 125px;
    bottom: -12px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid rgba(0,0,0,.8)
  }
}
.tipboxcarid{
  font-style: normal;
  text-align: center;
  border-radius: 8px;
  border:1px solid #f2aa2e;
  width: 30px;
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  color:#f2aa2e;
  border-radius: 50%;
  display: inline-block;
}
.popbtn{
  margin-top: 25px;
  div{
    margin-top: 5px!important;
    border-top: none!important;
    width: 210px;
    text-align: center;
    border-radius: 50px;
  }

}
.gocer{
  margin: 0 auto;
  width: 280px;
  height: 72px;
  font-size: 28px;
  line-height: 72px;
  margin-top: 30px;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  background-color: #FF2D2E;
}
.centerbox_dialog{
  text-align: left;
  padding: 0 80px;
  color: #666666;
  // padding: 0 10px;
  font-size: 28px;
}
  .flex{
  display: flex;
  justify-content: space-between;
  }
.confirmCenterbox_dialog{
  text-align: left;
  padding-bottom: 30px;
  font-size: 28px;
  border-bottom: 1px solid #E9E9E9;
}
.text{
  font-size: 20px;
  color: #666666;
  line-height: 38px;
  margin: 30px 0;
}
::v-deep .van-dialog{
    width: 90%;
    padding: 0 40px 30px;
    .title{
        color: #242424;

    }
  }
::v-deep .van-button{
    display: none
  }
.loadigboxdetals{
  height: 140px;
  .van-loading{
    padding-top: 200px;
  }
}
.Loading{
position: absolute;
top: 34%;
left: 50%;
margin-left: -40px;
}
.title{
  font-size: 32px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
.userselect{
  background: #fff;
  padding: 10px 0 40px;
  border-radius: 4px 4px 4px 4px;
  // background: red;
}
.countMumber{
  color:#D1D1D1;
  margin-right: 50px;
  margin-top: 12px;
  font-size: 36px;
}
.countAvailableMoney{
  color:#D1D1D1;
  margin-right: 50px;
  margin-top: 12px;
  font-size: 36px;
}
::v-deep .van-loading__spinner .van-loading__spinner--circular{
  font-size: 80px;
}
.centerboxloading{
  font-size: 80px;
}
.bottombox{
  padding: 0 30px 20px;
}
.citybox{
  color:#CCCCCC;
  font-size: 40px;
  margin: 0 30px;
}
.drepcolor{
  color:#292929;
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
}
::v-deep .van-picker__confirm{
  font-size: 32px;
}
::v-deep .van-picker__columns .van-picker-column:nth-child(2){
  display: none
}
::v-deep .van-picker-column__item{
  font-size: 30px;
}
::v-deep .el-input__inner{
  border:0;
  height: auto;
  font-size: 40px;
  padding: 0;
  text-align: right;
  background-color: #ffffff !important;

}
::v-deep .el-input{
  width: 30%;
  margin-right: 50px;
  margin-top: 4px;
}
::v-deep .el-input__inner::placeholder {
  color:#CCCCCC
}
  .weightbox{
  border-bottom: 1px solid #E0E0E0;
  padding: 4px 10px 22px;
  span{
    padding: 10px 0px;
  }
  .zuiDi{
    font-size: 22px;
    color: #D1D1D1;
  }
  }
  .unit{
    position: absolute;
    top: 30%;
    right: 28px;
    color: #242424;
    font-size: 40px;
  }
  .title_box{
  position: relative;
  margin: 0 !important;
  padding: 28px 18px 0 !important;
  span{
    font-size: 36px;
    color: #666666;
  }
.textTip{
  font-size: 20px;
  color: #666666;
  line-height: 34px;
}
  }
  .moneybox{
  padding:  0 30px;
  font-size: 40px;
  color:#CCCCCC;
  b{
    font-weight: normal;
  }
  }
  .title_box{
  font-size: 28px;
  color:#292929;
  margin: 0 30px;
  color:#292929;
  padding: 30px 0 30px;
  }
  .btnbox{
  width: 690px;
  background: #ff2d2e;
  color:#fff;
  margin: 0 auto;
  text-align: center;
  font-size: 36px;
  margin-top: 30px;
  line-height: 88px;
  border-radius: 50px;
  }
  .tipBox{
    padding: 60px 30px 70px;
    color:#999999;
    font-size: 24px;
    :nth-child(1){
      text-align: center;
      margin-bottom: 20px;
      position: relative;
      .leftLine{
        width: 80px;
        border: 1px solid #999999;
        position: absolute;
        top: 16px;
        left: 30%;
      }
      .rightLine{
        width: 80px;
        border: 1px solid #999999;
        position: absolute;
        top: 16px;
        right: 30%;
      }
    }
    div{
      line-height: 38px;
      margin-bottom: 10px;
    }
  }
  .yellowbox{
    text-align: center;
    background: #FEAE14;
    width: 750px;
    height: 200px;
    border-radius: 0 0 10% 10%;

  }
  .yellowfont{
    color:#FEAE14!important
  }
  .yellowfont ::v-deep .el-input__inner{
    color:#FEAE14!important;
    background-color: #FFFFFF !important;
  }
  .rel{
    background: #f5f5f5;
  }
  .boxgoldprice{
    // height: 200px;
    overflow: hidden;
    width: 700px;
    margin: 30px auto;
    background: url('~@/assets/images/pic_sell_price@2x.png') no-repeat;
    background-size: 100% 100%;
    h2{
      color:#FF4B71;
      font-weight: 600;
      font-size: 44px;
      margin-top: 34px;
    }
    .pricebox{
      margin-top: 0px;
      font-size: 30px;
      color:#242424;
      margin: 21px 0 30px;
    }
    .premium {
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
    }
  }
  .closebtn{
    background-color: #D1D1D1;
  }
  .confirmbtn{
    background-color:#FF2D2E ;
  }
  ._leftbox{
  padding-left: 10px;
  }
  .sellgold{
       background-color: #f5f5f5;
       padding-bottom: 40px;
       height: 100%;
  }
  .orderInfo{
    padding: 12px 0;
    .orderCol{
      color: #999999;
    }
  }
  .autograph{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    .autographText{
        width: 100%;
        height: 56px;
        line-height: 56px;
        color: #242424;
        background-color: #5473FF;
        font-size: 24px;
    }
    .autographBox{
        height: 200px;
        border: 1px solid #E9E9E9;
        img{
          width: 200px;
          height: 100%;
          transform:rotate(270deg);
        }
    }
  }
  ._leftbox{
  padding: 0 !important;
  }
  .ceringModel{
    width: 74%;
    .centerbox_dialog{
      padding: 0 0 30px !important;
      border-bottom: 1px solid #E9E9E9;
    }
    .confirm{
      margin: 30px 0  0;
       text-align: center;
    height: 40px;
    color: #FF2D2E;
    margin-top: 40px;
    font-size: 28px;
    line-height: 40px;
    }
  }
</style>
